// extracted by mini-css-extract-plugin
export var accordian = "header-module--accordian--xRmBI";
export var container = "header-module--container--YhtQ8";
export var desktop = "header-module--desktop--V4iNk";
export var hamburger = "header-module--hamburger--k0Fmu";
export var hamburgerLine = "header-module--hamburgerLine--4snXP";
export var iconWrapper = "header-module--iconWrapper--TzYyj";
export var isActive = "header-module--isActive--Bnwz0";
export var largeLink = "header-module--largeLink--8ivYy";
export var largeLinks = "header-module--largeLinks--7HvNe";
export var smallLink = "header-module--smallLink--PTGrh";
export var smallLinks = "header-module--smallLinks--XRhuK";